import arrayFrom from 'array-from';
import LazyLoad from 'vanilla-lazyload';

import CookieBanner from '../../../www/site/snippets/_generals/cookie-banner';
import DefaultSlider from '../../../www/site/snippets/organisms/org--default-slider';
import Cards from '../../../www/site/snippets/_blocks/_modules/mod--cards';
import TextSlider from '../../../www/site/snippets/_blocks/_modules/mod--text-slider';
import RichText from '../../../www/site/snippets/_blocks/_modules/mod--rich-text';
import MainNavigation from '../../../www/site/snippets/_generals/main-navigation';
import InstagramFeed from '../../../www/site/snippets/_blocks/_modules/mod--instagram-feed';


// OVERRIDES
/*
  override defineClassesMaster and defineClassesAdditional in sub projects of dotCMS master
 */
const defineClassesMaster = () => ({
  CookieBanner,
  DefaultSlider,
  Cards,
  TextSlider,
  RichText,
  MainNavigation,
  InstagramFeed,
});

// ToDo: put classes to their subproject
// ToDo: create new webpack entry points!

const defineClassesAdditional = () => (window.classesAdditional || {
});

// Global placeholder for optional additional entrypoints
// window.additionalEntrypoints = window.additionalEntrypoints || [];

// Global storage for optional additional classes/objects/modules
window.sharedObjects = window.sharedObjects || {};


// GLOBAL INIT
/*
  all components that have a javascript module attached must provide "data-module" attribute in markup with the name of their JS class
  then their JS module will be instantiated
 */
const getModuleForInit = () => {
  const classesMaster = defineClassesMaster();
  const classesAdditional = defineClassesAdditional();

  return Object.assign({}, classesMaster, classesAdditional);
};

// Add additional dom entrypoints before initializing modules
// const prepareAdditionalEntrypoints = (additionalEntrypoints) => {
//   additionalEntrypoints.forEach((entrypoint) => {
//     const parent = document.querySelector(entrypoint.selector);
//     parent.insertAdjacentHTML(entrypoint.insert, entrypoint.markup);
//   });
// };

const initModules = () => {

  // prepareAdditionalEntrypoints(window.additionalEntrypoints);

  // get all modules that will be used for auto initialization
  const classes = getModuleForInit();

  // get all modules to initialize on page
  const moduleElements = document.querySelectorAll('[data-module]');

  // initialize modules
  window.initializedModuleInstances = arrayFrom(moduleElements).map((moduleElement) => {
    const moduleClassName = moduleElement.getAttribute('data-module');

    try {
      if (moduleClassName !== '') {
        const instance = new (classes[moduleClassName])({
          element: moduleElement,
          // globalEventHandler,
        });
        instance.init();
        return instance;
      }
      return null;
    } catch (er) {
      console.error(`error initializing module instance with class "${moduleClassName}"`);
      console.error(er);
      return null;
    }
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initModules();

  // Vanilla LazyLoading for Images with class: lazy.
  // TODO: Replace with native lazyloading if possible in future.
  const lazyLoad = new LazyLoad({
    // container: this.element,
    elements_selector: '.lazy img, img.lazy',
    load_delay: 300,
    treshold: 300,
  });

  // console.log('Modules Initialized: ', getModuleForInit());
});
