import arrayFrom from 'array-from';
import Cookie from 'js-cookie';
import { addClass, removeClass } from '../../../../assets/javascript/tools/helpers';

export const CLASSNAME_CONSENT_RELEVANT = 'consent-relevant';
export const CLASSNAME_SHOWN = 'shown';
export const VALUE_COOKIE_CONSENT = 'hasConsent';

class CookieBanner {
  constructor(options = {}) {
    this.element = options.element;
    this.consentRelevantScripts = null;
    this.defaultState = false;
    this.acceptButton = this.element.querySelector('.cookie-accept');
    this.rejectButton = this.element.querySelector('.cookie-reject');
  }

  init() {
    this.addEvents();

    if (Cookie.get(VALUE_COOKIE_CONSENT) === undefined) {
      this.showModal();
    } else if (Cookie.get(VALUE_COOKIE_CONSENT) === 'true'){
      this.fetchConsentRelevantScripts();
    }
  }

  setDefaultState() {
    if (Cookie.get(VALUE_COOKIE_CONSENT).toString() !== 'false' || Cookie.get(VALUE_COOKIE_CONSENT).toString() !== 'true') {
      Cookie.set(VALUE_COOKIE_CONSENT, 'null');
    }
  }

  setAcceptState() {
    Cookie.set(VALUE_COOKIE_CONSENT, 'true');
  }

  setRejectState() {
    Cookie.set(VALUE_COOKIE_CONSENT, 'false');
  }

  fetchConsentRelevantScripts() {
    this.consentRelevantScripts = document.querySelectorAll(`script.${CLASSNAME_CONSENT_RELEVANT}`);
    arrayFrom(this.consentRelevantScripts).forEach((script) => {
      this.initScriptCode(script);
    });
  }

  addEvents() {
    this.acceptButton.addEventListener('click', () => {
      this.setAcceptState();
      this.fetchConsentRelevantScripts();
      this.hideModal();
    });

    this.rejectButton.addEventListener('click', () => {
      this.setRejectState();
      this.hideModal();
    });
  }

  initScriptCode(script) {
    new Function(script.innerHTML).call();
  }

  showModal() {
    addClass(this.element, CLASSNAME_SHOWN);
  }

  hideModal() {
    removeClass(this.element, CLASSNAME_SHOWN);
  }
}

export default CookieBanner;
