import { hasClass, addClass, removeClass, toggleClass } from '../../../../assets/javascript/tools/helpers';

const CLASSNAME_TOGGLE = 'main-navigation__toggle';
const CLASSNAME_OVERLAY = 'main-navigation__overlay';

class MainNavigation {
  constructor(options = {}) {
    this.element = options.element;
    this.toggle = this.element.querySelector(`.${CLASSNAME_TOGGLE}`);
    this.overlay = this.element.querySelector(`.${CLASSNAME_OVERLAY}`);
  }

  init () {
    this.toggle.addEventListener('click', () => {
      toggleClass(this.element, 'overlay-active');
      toggleClass(this.overlay, 'active');
      toggleClass(this.toggle, 'active');
    });

    this.overlay.addEventListener('click', () => {
      toggleClass(this.element, 'overlay-active');
      toggleClass(this.overlay, 'active');
      toggleClass(this.toggle, 'active');
    });


    this.scrollToggle();
  }

  scrollToggle() {

    let scrollPos = window.pageYOffset;

    this.toggleSticky(scrollPos);

    window.addEventListener('scroll', (event) => this.toggleSticky(window.pageYOffset));
  }

  toggleSticky(posY) {
    // console.log(posY)
    const offset = 300;

    if (posY >= offset) {
      addClass(this.element, 'sticky');
    } else {
      removeClass(this.element, 'sticky');
    }
  }
}

export default MainNavigation;
