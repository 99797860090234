import arrayFrom from 'array-from';
import Glide from '@glidejs/glide'
import { addClass, wrapElement } from '../../../../assets/javascript/tools/helpers';

const CLASSNAME_GLIDE_WRAPPER = 'slider--glide';
const CLASSNAME_GLIDE_SLIDES = 'glide__slides';
const CLASSNAME_GLIDE_SLIDE = 'glide__slide';
const CLASSNAME_GLIDE_TRACK = 'glide__track';
const ARROWS_CONTENT_LEFT = `<i class="la la-lg la-angle-left"></i>`;
const ARROWS_CONTENT_RIGHT = `<i class="la la-lg la-angle-right"></i>`

class DefaultSlider {
  constructor(options = {}) {
    this.element = options.element;
    this.slides = this.element.children;
    this.controls = this.element.dataset.controls || false;
    this.arrows = this.element.dataset.navigation || true;
    this.startAt = this.element.dataset.startAt || 0;
    this.perView = this.element.dataset.perView || 1;
    this.gap = this.element.dataset.gap || 0;
    this.autoplay = this.element.dataset.autoplay || false;
    this.type = this.element.dataset.type || 'slider';
  }

  init() {
    this.sliderOptions = {
      element: this.element,
      type: this.type,
      startAt: this.startAt,
      autoplay: this.autoplay,
      perView: this.perView,
      gap: this.gap,
      focusAt: 'center',
    };

    // Starting Restructuring only when more than one child is present.
    if (this.slides.length > 1) {
      this.applyStructure();
    } else {
      return false;
    }
  }

  applyStructure() {
    const glideSlides = document.createElement('ul');
    addClass(glideSlides, CLASSNAME_GLIDE_SLIDES);
    arrayFrom(this.slides).forEach((slide) => {
      glideSlides.appendChild(wrapElement(slide, 'li', CLASSNAME_GLIDE_SLIDE));
    });

    const glideTrack = document.createElement('div');
    addClass(glideTrack, CLASSNAME_GLIDE_TRACK);
    glideTrack.setAttribute('data-glide-el', 'track');
    glideTrack.appendChild(glideSlides);

    const glide = document.createElement('div');
    addClass(glide, CLASSNAME_GLIDE_WRAPPER);
    glide.appendChild(glideTrack);

    this.element.appendChild(glide);
    // console.log(this.arrows)

    if (this.arrows === true || this.arrows === 'true') {
      glide.insertAdjacentHTML('beforeend', this.applyArrows());
    }

    if (this.controls === true || this.controls === 'true') {
      glide.insertAdjacentHTML('beforeend', this.applyControls());
    }

    this.initSlider(this.sliderOptions);
  }

  applyArrows() {
    // console.log('Applying Arrows');
    const arrowsTemplate = `
      <div class="glide__arrows" data-glide-el="controls">
        <button class="glide__arrow glide__arrow--left" data-glide-dir="<">${ARROWS_CONTENT_LEFT}</button>
        <button class="glide__arrow glide__arrow--right" data-glide-dir=">">${ARROWS_CONTENT_RIGHT}</button>
      </div>
    `;
    return arrowsTemplate;
  }

  applyControls() {
    // console.log('Applying Controls');
    let controlsTemplate = `
      <div class="glide__controls" data-glide-el="controls[nav]">
    `;
    arrayFrom(this.element.querySelectorAll(`.${CLASSNAME_GLIDE_SLIDE}`)).forEach((slide, index) => {
      controlsTemplate += `<button class="glide__bullet" data-to="${slide}" data-glide-dir="=${index}"></button>`;
    });

    controlsTemplate += `</div>`;

    return controlsTemplate;
  }

  initSlider(options) {
    const slider = new Glide(this.element.querySelector(`.${CLASSNAME_GLIDE_WRAPPER}`), options);
    slider.mount();
  }
}

export default DefaultSlider;
