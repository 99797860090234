import DefaultSlider from '../../../organisms/org--default-slider';

class Cards extends DefaultSlider {
  constructor(options = {}) {
    super(options);
  }

  init() {
    super.init();
  }
}

export default Cards;
