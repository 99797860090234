import arrayFrom from 'array-from';

const iconInsert = (element, type) => {
  const template = `
    <i class="la la-lg la-${type}"></i>
  `;
  element.insertAdjacentHTML('afterbegin', template);
};

class RichText {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
    this.insertIcons();
  }

  insertIcons() {
    const anchors = this.element.querySelectorAll('a[href^="mailto:"]');
    arrayFrom(anchors).forEach((anchor) => {
      iconInsert(anchor, 'envelope');
    });
  }
}

export default RichText;
